const domainNameClient = 'https://dev.eqo-pack.quantis.solutions';
export const environment = {
  production: true,
  name: 'development',
  url: domainNameClient,
  apiUrl: 'https://api.dev.eqo-pack.quantis.solutions/v2',
  enableSentry: true,
  userManagement: {
    identityPoolId: 'eu-west-1:6a0c6f08-4f5f-4890-9825-62e4d33c9fdd',
    centralUserPoolDomain: 'cognito-idp.eu-west-1.amazonaws.com/eu-west-1_7UuMBQIBi',
  },
  Auth: {
    region: 'eu-west-1',
    userPoolId: 'eu-west-1_7UuMBQIBi',
    userPoolWebClientId: '1bt76qlntriqlephrl7fc48q15',
    oauth: {
      domain: 'qristo-development.auth.eu-west-1.amazoncognito.com',
      scope: ['openid'],
      redirectSignIn: `${domainNameClient}/signin`,
      redirectSignOut: `${domainNameClient}/signout`,
      responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
    }
  },
  features: {
    portfolio: true,
    multipleMarkets: true,
  },
  theme: 'default',
};
